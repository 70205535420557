import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Switch } from '@mui/material';
import { getData } from '../utils/dataLoader';
import './SiteDashboard.css';
import './DashSideBar.css';
import DashSideBar from './DashSideBar'; 

// Register chart elements
ChartJS.register(ArcElement, Tooltip, Legend);

const SiteDashboard = () => {
  const { siteName } = useParams(); // Get site name from route parameters

  const [siteData, setSiteData] = useState(null);  // Holds site data
  const [loading, setLoading] = useState(true);  // Loading state
  const [error, setError] = useState(null);  // Error state
  const [selectedDate, setSelectedDate] = useState(null);  // Holds selected date
  const [availableDates, setAvailableDates] = useState([]);  // Available date folders
  const [siteConfig, setSiteConfig] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'inverterBlock', direction: 'ascending' }); // Sorting configuration
  const [kWPerPanel, setKWPerPanel] = useState(0.5); // Set default value of kW per panel
  const [impactedKW, setImpactedKW] = useState(0);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [energyRate, setEnergyRate] = useState(0.12); 


  const PVWATTS_API_URL = 'https://developer.nrel.gov/api/pvwatts/v6.json';
  const PVWATTS_API_KEY = process.env.REACT_APP_PVWATTS_API_KEY;

  const navigate = useNavigate();

  const handleDarkModeToggle = () => {
    setIsDarkMode(!isDarkMode);
    if (!isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  };

  const fetchPVWattsData = async (lat, lng, systemSize) => {
    try {
      const response = await axios.get(PVWATTS_API_URL, {
        params: {
          api_key: PVWATTS_API_KEY,
          lat: lat,
          lon: lng,
          system_capacity: systemSize,
          module_type: 0,
          losses: 10,
          array_type: 1,
          tilt: 20,
          azimuth: 180,
        },
      });
      return response.data.outputs;
    } catch (error) {
      console.error('Error fetching PVWatts data:', error);
      return null;
    }
  };

  const formatDate = (dateString) => {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    
    if (typeof dateString === 'string' && dateString.length === 8) {
      const month = parseInt(dateString.slice(0, 2), 10) - 1;
      const day = parseInt(dateString.slice(2, 4), 10);
      const year = parseInt(dateString.slice(4), 10);

      return `${monthNames[month]} ${day}, ${year}`;
    } else {
      return dateString; 
    }
  };

  // Fetch site config and data
  useEffect(() => {
    const fetchSiteConfig = async () => {
      const baseUrl = `https://storage.googleapis.com/insite-data-files/files/${encodeURIComponent(siteName)}`;
      const siteConfigUrl = `${baseUrl}/siteConfig.json`;

      try {
        const response = await fetch(siteConfigUrl);
        const configData = await response.json();
        const dateFolders = configData.dateFolders || [];

        if (dateFolders.length > 0) {
          setAvailableDates(dateFolders);
          setSelectedDate(dateFolders[0].date); // Default to the first available date
        }
        setSiteConfig(configData); // Store config in state
      } catch (error) {
        console.error('Error fetching siteConfig:', error);
      }
    };

    fetchSiteConfig();
  }, [siteName]);

  useEffect(() => {
    // Calculate total panels affected and percent affected
    if (siteData) {
      const totalPanelsAffected = Object.values(siteData.effectedPanelsByRectangleId).reduce((sum, affected) => sum + affected, 0);
      const percentAffected = ((totalPanelsAffected / siteData.panelCount) * 100).toFixed(2);
      const impactedKWValue = (totalPanelsAffected * kWPerPanel).toFixed(2);
      setImpactedKW(impactedKWValue);

      setSiteData(prevData => ({
        ...prevData,
        totalPanelsAffected,
        percentAffected,
      }));
    }
  }, [siteData, kWPerPanel]);

  useEffect(() => {
    const calculateKWhLost = async () => {
      if (siteData && impactedKW > 0) {
        const { lat, lng } = siteData.center;

        // Make the API call to PVWatts using impacted kW as the system size
        const pvData = await fetchPVWattsData(lat, lng, impactedKW);
        
        if (pvData) {
          setSiteData(prevData => ({
            ...prevData,
            kWhLost: pvData.ac_annual.toFixed(2), // Assuming ac_annual contains the estimated annual kWh
          }));
        }
      }
    };

    calculateKWhLost();
  }, [impactedKW, siteData]);

  // Handle kW per panel input changes
  const handleKWPerPanelChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    setKWPerPanel(value);
  };

  const revenueLost = siteData?.kWhLost ? (siteData.kWhLost * energyRate).toFixed(2) : null;
  //HEY LOOK OVER HERE-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=

  useEffect(() => {
    if (selectedDate && siteConfig) {
      const selectedDateConfig = siteConfig.dateFolders.find(dateFolder => dateFolder.date === selectedDate);

      if (!selectedDateConfig) {
        console.error('No config found for the selected date');
        setError('No config found for the selected date');
        setLoading(false);
        return;
      }

      const { layers, IB, levels } = selectedDateConfig;

      const fetchData = async () => {
        const baseUrl = `https://storage.googleapis.com/insite-data-files/files/${encodeURIComponent(siteName)}/${encodeURIComponent(selectedDate)}`;
        try {
          const data = await getData(baseUrl, layers, IB, levels);  // Pass the configs to getData
          setSiteData(data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching site data:', error);
          setError('Failed to fetch site data');
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [siteName, selectedDate, siteConfig]);

  // Pie chart data construction
  const generatePieData = () => {
    if (!siteData || !siteData.groupedDataByLevel) return null;

    const faultCategories = [];

    // Collect affected panels from all fault categories
    Object.entries(siteData.groupedDataByLevel).forEach(([level, categories]) => {
      Object.entries(categories).forEach(([categoryName, affectedPanels]) => {
        if (affectedPanels > 0) { // Only include categories with affected panels
          const decodedCategoryName = decodeURIComponent(categoryName); // Replace %20 with spaces
          faultCategories.push({
            category: decodedCategoryName,
            affected: affectedPanels,
          });
        }
      });
    });

    // Pie chart dataset with only affected panels
    const pieData = {
      labels: faultCategories.map(c => c.category), // Labels are the fault categories
      datasets: [
        {
          data: faultCategories.map(c => c.affected), // Data is the number of affected panels for each category
          backgroundColor: ['#ff6384', '#36a2eb', '#ffce56', '#4bc0c0', '#9966ff', '#f56942'], 
          hoverOffset: 4,
        },
      ],
    };

    return pieData;
  };
  
  if (loading) {
    return <p>Loading...</p>;
  }

  if (!siteData) {
    return <p>No data available for this site.</p>;
  }

  const sortTable = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Sorting the table rows based on the current sort configuration
  const sortedTableData = (tableData) => {
    return [...tableData].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Handle numerical sorting for inverter blocks (IB1, IB2...) and percentages
      if (sortConfig.key === 'inverterBlock') {
        aValue = parseInt(a.inverterBlock.replace('IB', '')); // Extract the number part of IB1, IB2
        bValue = parseInt(b.inverterBlock.replace('IB', ''));
      } else if (sortConfig.key === 'percentageAffected') {
        aValue = parseFloat(a.percentageAffected); // Convert percentages to floats
        bValue = parseFloat(b.percentageAffected);
      }

      if (aValue < bValue) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  };

  // Construct the data table
  const generateTableData = () => {
    return Object.entries(siteData.effectedPanelsByRectangleId).map(([blockId, affectedPanels]) => {
      const totalPanels = siteData.rectangles.find(rect => rect.id === blockId)?.pCount || 0;  // Get the total panels for the inverter block
      const percentageAffected = ((affectedPanels / totalPanels) * 100).toFixed(2);

      return {
        inverterBlock: blockId,
        totalPanels,
        affectedPanels,
        percentageAffected,
      };
    });
  };

  // Navigate to the interactive map (Home.js)
  const handleMapNavigation = () => {
    navigate(`/home/${encodeURIComponent(siteName)}`);
  };

  const tableData = sortedTableData(generateTableData());

  return (
    <div className="dashboard-container">
      <DashSideBar
        availableDates={availableDates}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        formatDate={formatDate}
      />
  
      <div className="dashboard-main">
        <div className="dashboard-header">
          <h1>{siteName} - Dashboard</h1>
          <h2>Selected Date: {formatDate(selectedDate)}</h2>
        </div>
  
        <div className="dashboard-grid">
          {/* Pie Chart Section */}
          <div className="dashboard-pie-chart">
            <h2>Fault Distribution</h2>
            <Pie data={generatePieData()} style={{ width: '100%', height: '100%' }} />
          </div>
  
           {/* Site Data */}
 
            <div className="dashboard-site-data">
              <h2>Site Data</h2>
              <p><strong>Center:</strong> {siteData.center.lat}, {siteData.center.lng}</p>
              <p><strong>Total Panels:</strong> {siteData.panelCount}</p>
              <p><strong>Total Faults:</strong> {siteData.statistics.totalFaultsFound}</p>
              <p><strong>Total Panels Affected:</strong> {siteData.totalPanelsAffected}</p>
              <p><strong>Percent Affected:</strong> {siteData.percentAffected}%</p>
              <p><strong>Estimated Annual kWh Lost:</strong> {siteData.kWhLost ? `${siteData.kWhLost} kWh` : 'Calculating...'}</p>

              <div className="kw-input">
                <label htmlFor="kwPerPanel">kW Per Panel: </label>
                <input 
                  type="number" 
                  id="kwPerPanel" 
                  value={kWPerPanel} 
                  onChange={handleKWPerPanelChange}
                />
              </div>

              <p><strong>Impacted kW:</strong> {impactedKW} kW</p>

              <div className="energy-rate-input">
                <label htmlFor="energyRate">Average Energy Rate ($/kWh): </label>
                <input 
                  type="number" 
                  id="energyRate" 
                  value={energyRate} 
                  onChange={(e) => setEnergyRate(parseFloat(e.target.value) || 0)}
                />
              </div>

              <p><strong>Estimated Annual Revenue Lost:</strong> {revenueLost ? `$${revenueLost}` : 'Calculating...'}</p>

              <p style={{ fontSize: '0.8em', color: 'gray', marginTop: '10px' }}>
                *These numbers are estimates based on PVWatts calculations and may not reflect actual energy production or revenue.
              </p>

            </div>


  
          {/* Map Overview */}
          <div className="dashboard-map-preview">
            <h2>Go To Map Overview</h2>
            <Button onClick={handleMapNavigation}>
              <img src="/previewImg.jpg" alt="Map Preview" className="map-preview-image" />
            </Button>
          </div>
  
          {/* Data Table */}
          <div className="dashboard-data-table" style={{ maxHeight: '400px', overflowY: 'auto' }}>
            <h2>Inverter Block Data</h2>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell onClick={() => sortTable('inverterBlock')}>
                      Inverter Block {sortConfig.key === 'inverterBlock' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                    </TableCell>
                    <TableCell onClick={() => sortTable('totalPanels')}>
                      Total Panels {sortConfig.key === 'totalPanels' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                    </TableCell>
                    <TableCell onClick={() => sortTable('affectedPanels')}>
                      Affected Panels {sortConfig.key === 'affectedPanels' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                    </TableCell>
                    <TableCell onClick={() => sortTable('percentageAffected')}>
                      % Affected {sortConfig.key === 'percentageAffected' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.inverterBlock}</TableCell>
                      <TableCell>{row.totalPanels}</TableCell>
                      <TableCell>{row.affectedPanels}</TableCell>
                      <TableCell>{row.percentageAffected}%</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>

        {/* Dark Mode Switch */}
        <div className="dark-mode-toggle">
          <label>Dark Mode</label>
          <Switch 
            checked={isDarkMode} 
            onChange={handleDarkModeToggle}
            color="default"
          />
        </div>

        {/* Footer Section */}
        <div className="dashboard-footer">
          &copy; {new Date().getFullYear()} HorizonView Technologies
        </div>

      </div>
    </div>
  );  
};

export default SiteDashboard;
