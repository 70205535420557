import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './FilterMenu.css';

const FilterMenu = ({ 
  categories, 
  selectedCategories, 
  handleCategoryChange, 
  handleOverlayToggle, 
  otherOverlays, 
  overlayVisibility, 
  selectedRectangle, 
  imagesByRectangleId 
}) => {
  const isOverlayVisible = (index) => (overlayVisibility & (1 << index)) !== 0;

  const images = selectedRectangle ? imagesByRectangleId[selectedRectangle] || [] : [];

  const categoryData = images.reduce((acc, item) => {
    if (!acc[item.category]) {
      acc[item.category] = { category: item.category, affectedPanels: 0 };
    }
    acc[item.category].affectedPanels += item.effected_panels || 0;
    return acc;
  }, {});

  const tableData = Object.values(categoryData);

  const totalAffectedPanels = tableData.reduce((total, row) => total + row.affectedPanels, 0);

  return (
    <div className="filter-menu">

      {selectedRectangle && images.length > 0 && (
        <div className="carousel-container">
          <h2>Images for {selectedRectangle}</h2>
          <Carousel
            key={selectedRectangle} 
            showThumbs={false}
            showStatus={true}
            showIndicators={false}
          >
            {images.map((item, index) => (
              <div key={index}>
                <div className="carousel-item-container">
                  <img 
                    src={item.imagePath} 
                    alt={`Failure at ${item.failure_location}`} 
                  />
                </div>
                <div className="carousel-caption">
                  <p>Failure Type: {item.category}</p>
                  <p>Location: {item.failure_location}</p>
                  <p>Lat: {item.lat}, Lng: {item.lng}</p>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      )}

      {selectedRectangle && tableData.length > 0 && (
        <div className="filter-table-container">
          <h3>Failures For {selectedRectangle}</h3>
          <table className="filter-data-table">
            <thead>
              <tr>
                <th>Category</th>
                <th>Affected Panels</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td>{row.category}</td>
                  <td>{row.affectedPanels}</td>
                </tr>
              ))}

              <tr>
                <td><strong>Total</strong></td>
                <td><strong>{totalAffectedPanels}</strong></td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

    </div>
  );
};

export default FilterMenu;
