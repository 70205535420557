import React, { useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { auth } from '../utils/firebase';
import './Sidebar.css';

const Sidebar = ({ isMinimized, toggle, folderName, dateFolders, onDateSelect }) => {
  const navigate = useNavigate(); // Initialize useNavigate for programmatic navigation

  const formatDate = (dateString) => {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    
    if (typeof dateString === 'string' && dateString.length === 8) {
      const month = parseInt(dateString.slice(0, 2), 10) - 1;
      const day = parseInt(dateString.slice(2, 4), 10);
      const year = parseInt(dateString.slice(4), 10);

      return `${monthNames[month]} ${day}, ${year}`;
    } else {
      return dateString; 
    }
  };
  
  const handleSignOut = () => {
    auth.signOut()
      .then(() => {
        console.log('User signed out');
        navigate('/login'); 
      })
      .catch((error) => {
        console.error('Sign out error', error);
      });
  };

  useEffect(() => {
    console.log('Sidebar component loaded');
    console.log('Folder Name:', folderName);
    console.log('Date Folders:', dateFolders);
  }, [folderName, dateFolders]);

  return (
    <div className={`sidebar ${isMinimized ? 'minimized' : ''}`}>
      <FaBars onClick={toggle} className="menu-icon" />
      <nav className={`nav-menu ${isMinimized ? 'hide' : ''}`}>
        <p><b>Site: {folderName}</b></p>
        <ul>
          {Array.isArray(dateFolders) && dateFolders.length > 0 ? (
            dateFolders.map(dateFolder => {
              console.log('Current dateFolder object:', dateFolder);
              const dateValue = dateFolder.date || dateFolder; // Modify this line based on what you observe
              return (
                <li key={dateValue} onClick={() => onDateSelect(dateValue)}>
                  {formatDate(dateValue)}
                </li>
              );
            })
          ) : (
            <li>No date folders available</li>
          )}
        </ul>
        {/* Button to return to Admin Page */}
        <button className="back-to-admin-button" onClick={() => navigate('/admin')}>
          Back to Data Dashboard
        </button>
        {/* Sign Out Button */}
        <button className="sign-out-button" onClick={handleSignOut}>
          Sign Out
        </button>
      </nav>
    </div>
  );
};

export default Sidebar;
