import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import MainView from './MainView';
import FilterMenu from './FilterMenu';
import StatisticsView from './StatisticsView';
import { getData } from '../utils/dataLoader';
import './Home.css';
import { fetchPermissibleSites } from '../utils/firebase';

function Home({ currentUser, onDataLoaded }) {

  const { siteName } = useParams();
  const decodedSiteName = decodeURIComponent(siteName);
  const navigate = useNavigate();

  const [annotations, setAnnotations] = useState([]);
  const [rectangles, setRectangles] = useState([]);
  const [isMinimized, setMinimized] = useState(false);
  const [isStatisticsVisible, setStatisticsVisible] = useState(false);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [faults, setFaults] = useState([]); 
  const [statistics, setStatistics] = useState({
    totalPanelCount: 0,
    totalFaultsFound: 0,
    totalPanelsEffected: 0,
    potentialMaxKWGeneration: 0,
    lostKWDueToFaults: 0,
    percentLost: 0,
    missedOutRevenue: 0,
  });

  const [sites, setSites] = useState([]);
  const [folderName, setFolderName] = useState('');
  const [dateFolders, setDateFolders] = useState([]); // State to hold date folders
  const [siteConfig, setSiteConfig] = useState(null);
  const [mainOverlay, setMainOverlay] = useState(null);
  const [otherOverlays, setOtherOverlays] = useState([]);
  const [overlayVisibility, setOverlayVisibility] = useState(0);
  const [effectedPanelsByRectangleId, setEffectedPanelsByRectangleId] = useState({});
  const [imagesByRectangleId, setImagesByRectangleId] = useState({});
  const [selectedRectangle, setSelectedRectangle] = useState(null);
  const [IBImages, setIBImages] = useState({});
  const [loading, setLoading] = useState(true); // Add loading state

  const defaultOverlayOpacity = 0.4;

  useEffect(() => {
    if (siteName) {
      loadSiteConfig(siteName);
    }
  }, [siteName]); // Re-run when siteName changes

  const loadSiteConfig = (siteName) => {
    const encodedSiteName = encodeURIComponent(siteName);
    const dataUrl = `https://storage.googleapis.com/insite-data-files/files/${encodedSiteName}/siteConfig.json?v=${new Date().getTime()}`;
    console.log('Fetching siteConfig from:', dataUrl);

    setLoading(true); // Set loading to true when starting to fetch config

    fetch(dataUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch siteConfig.json');
        }
        return response.json();
      })
      .then((config) => {
        console.log('Fetched siteConfig:', config);
        setSiteConfig(config);
        setDateFolders(config.dateFolders.map(folder => folder.date)); // Assuming `dateFolders` has `date` properties

        // Automatically load data for the first date folder
        if (config.dateFolders.length > 0) {
          const firstDate = config.dateFolders[0].date;
          loadData(siteName, firstDate, config);
        } else {
          setLoading(false); // No date folders to load
        }
      })
      .catch((error) => {
        console.error('Error fetching site config:', error);
        alert('Error fetching site config. Please check the console for details.');
        setLoading(false); // Set loading to false if there's an error
      });
  };

  const loadData = (siteName, selectedDate, config = siteConfig) => {
    if (!config) {
      console.error('No siteConfig available');
      return;
    }

    setLoading(true); // Set loading to true when starting to fetch data

    const dateFolder = config.dateFolders.find(folder => folder.date === selectedDate);
    if (!dateFolder) {
      console.error('No matching date folder found:', selectedDate);
      setLoading(false); // Set loading to false if there's an error
      return;
    }

    const { layers, IB, levels } = dateFolder;
    if (!layers || !IB || !levels) {
      console.error('Missing layers, IB, or levels in siteConfig:', dateFolder);
      setLoading(false); // Set loading to false if there's an error
      return;
    }

    const encodedSiteName = encodeURIComponent(siteName);
    const encodedDate = encodeURIComponent(selectedDate);
    const dataUrl = `https://storage.googleapis.com/insite-data-files/files/${encodedSiteName}/${encodedDate}`;

    console.log('Fetching data from:', dataUrl);

    getData(dataUrl, layers, IB, levels)
      .then(data => {
        console.log('Data fetched:', data);
        setCenter(data.center);
        setAnnotations(data.annotations);
        setCategories(data.categories);
        setSelectedCategories(data.categories);
        setStatistics(data.statistics);
        setMainOverlay(data.mainOverlay);
        setOtherOverlays(data.otherOverlays);
        setRectangles(data.rectangles);
        setEffectedPanelsByRectangleId(data.effectedPanelsByRectangleId);
        setImagesByRectangleId(data.imagesByRectangleId);
        setIBImages(data.IBImages);
        setFaults(data.annotations);

        const visibilityState = (1 << data.otherOverlays.length) - 1;
        setOverlayVisibility(visibilityState);

        if (onDataLoaded) {
          onDataLoaded({
            statistics: data.statistics,
            // Add other data you need for charts
          });
        }

        setLoading(false); // Set loading to false after data is loaded
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        alert('Error fetching data. Please check the console for details.');
        setLoading(false); // Set loading to false if there's an error
      });
  };

  const handleDateSelect = (dateFolder) => {
    loadData(folderName, dateFolder); // Use folderName to get the site name
  };

  const handleCategoryChange = useCallback((category) => {
    setSelectedCategories(prevSelectedCategories => {
      if (prevSelectedCategories.includes(category)) {
        return prevSelectedCategories.filter(c => c !== category);
      } else {
        return [...prevSelectedCategories, category];
      }
    });
  }, []);

  const toggleSidebar = useCallback(() => {
    setMinimized(prev => !prev);
  }, []);

  const toggleStatisticsView = useCallback(() => {
    setStatisticsVisible(prev => !prev);
  }, []);

  const handleOverlayToggle = useCallback((index, isChecked) => {
    setOverlayVisibility(prevVisibility => {
      if (isChecked) {
        return prevVisibility | (1 << index); // Set the bit at `index` to 1
      } else {
        return prevVisibility & ~(1 << index); // Set the bit at `index` to 0
      }
    });
  }, []);

  const handleRectangleClick = useCallback((rectangleId) => {
    setSelectedRectangle(rectangleId); // Set the selected rectangle ID
  }, []);

  return (
    <div className="Home">
      {loading ? (
        <div className="loading-container">
          <img src="../HZVTLogo.png" alt="Logo" className="logo" />
          <p>Loading Site Data...</p>
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          <Sidebar 
            isMinimized={isMinimized} 
            toggle={toggleSidebar} 
            folderName={folderName} 
            dateFolders={dateFolders}
            onDateSelect={handleDateSelect}
          />
          <div className={`main-container ${isMinimized ? 'expanded' : ''}`}>
            <MainView 
              annotations={annotations} 
              center={center} 
              selectedCategories={selectedCategories} 
              mainOverlay={mainOverlay} 
              otherOverlays={otherOverlays} 
              overlayVisibility={overlayVisibility} 
              overlayOpacity={defaultOverlayOpacity} 
              faults={faults}
              rectangles={rectangles}
              effectedPanelsByRectangleId={effectedPanelsByRectangleId}
              imagesByRectangleId={imagesByRectangleId}
              onRectangleClick={handleRectangleClick}
              IBImages={IBImages}
            />
            <FilterMenu 
              categories={categories} 
              selectedCategories={selectedCategories} 
              handleCategoryChange={handleCategoryChange} 
              handleOverlayToggle={handleOverlayToggle}
              otherOverlays={otherOverlays} 
              overlayVisibility={overlayVisibility}
              selectedRectangle={selectedRectangle} 
              imagesByRectangleId={imagesByRectangleId}
            />
          </div>
          <StatisticsView 
            isVisible={isStatisticsVisible} 
            toggleVisibility={toggleStatisticsView} 
            statistics={statistics} 
            imagesByRectangleId = {imagesByRectangleId}
          />
        </>
      )}
    </div>
  );
}

export default Home;


/*

http://localhost:3000/static/03182024

http://localhost:8080/static/03182024

*/

//https://imgur.com/a/U6cxmjy

//https://i.imgur.com/9UfOE8S.jpeg