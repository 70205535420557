import React, { useState, useCallback, useEffect } from 'react';
import { GoogleMap, useLoadScript, Rectangle, InfoWindow, GroundOverlay } from '@react-google-maps/api';
import { useParams } from 'react-router-dom';
import './MainView.css';

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const MainView = ({
  center,
  rectangles = [],
  faults = [],  // Add the faults array
  mainOverlay = {},
  otherOverlays = [],
  overlayOpacity,
  overlayVisibility,
  effectedPanelsByRectangleId = {},
  imagesByRectangleId = {},
  onRectangleClick,
  IBImages
}) => {

  const { siteName } = useParams();

  const containerStyle = {
    width: '100%',
    height: '100vh'
  };

  const [map, setMap] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(15.75);
  const [selectedRectangle, setSelectedRectangle] = useState(null);
  const [selectedFault, setSelectedFault] = useState(null);  // Add selected fault state
  const [hoveredFaultIndex, setHoveredFaultIndex] = useState(null); // Add a state to track the hovered fault index
  const [currentOverlayOpacity, setCurrentOverlayOpacity] = useState(overlayOpacity);

  const onLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
  }, []);

  const onZoomChanged = useCallback(() => {
    if (map) {
      setZoomLevel(map.getZoom());
    }
  }, [map]);

  useEffect(() => {
    if (zoomLevel <= 16) {
      setCurrentOverlayOpacity(overlayOpacity); // Full opacity when zoomed in
    } else {
      setCurrentOverlayOpacity(1); // Original opacity when zoomed out
    }
  }, [zoomLevel, overlayOpacity]);

  const isOverlayVisible = (index) => (overlayVisibility & (1 << index)) !== 0;


  const renderInfoWindowContent = (rectangleId) => {
    const totalEffectedPanels = effectedPanelsByRectangleId[rectangleId] || 0;
    const IBImage = IBImages[rectangleId];

    return (
      <div className="info-window-content">
        <b>Total Affected Panels: {totalEffectedPanels}</b>
        {IBImage && (
          <div>
            <img 
              src={IBImage} 
              alt={`Inverter Block ${rectangleId}`} 
              className="ib-image" 
            />
          </div>
        )}
      </div>
    );
  };

  const renderFaultInfoWindowContent = (fault, category, level, siteName) => {
     
    const baseUrl = `https://storage.googleapis.com/insite-data-files/files/${encodeURIComponent(siteName)}/03182024/${level}/${category}/images`;
    const imageUrl = `${baseUrl}/${fault.image}`;
  
    return (
      <div className="info-window-content">
        <b>Affected Panels: {fault.effected_panels}</b>
        <p>Failure Location: {fault.failure_location}</p>
        <p>Category: {fault.category}</p>
        <p>Level: {fault.level}</p>
        {fault.image && (
          <div>
            <img 
              src={imageUrl} 
              alt={`Fault Image: ${fault.failure_location}`} 
              style={{
                width: '100%', 
                height: 'auto', 
                maxHeight: '200px',  // Set the maximum height
                marginTop: '10px' 
              }}
            />
          </div>
        )}
      </div>
    );
  };
  
  

  const getRectangleBounds = (lat, lng, sizeFactor) => {
    let latOffset, lngOffset;

    // Log the sizeFactor being passed
    console.log('SizeFactor:', sizeFactor);

    if (sizeFactor < 10) {
      sizeFactor = 10;
      console.log('Adjusted SizeFactor to 10');
    }

    //lat is up and down
    //lng is left and right
    //If you F12'ed and found this, no you didn't

    if (sizeFactor < 20) {
      latOffset = 0.0000497460625; 
      lngOffset = 0.000024403295;
      console.log('SizeFactor < 20, latOffset:', latOffset, 'lngOffset:', lngOffset);
    } else if (sizeFactor === 20) {
      latOffset = 0.000099492125;
      lngOffset = 0.000024403295;
      console.log('SizeFactor = 20, latOffset:', latOffset, 'lngOffset:', lngOffset);
    } else if (sizeFactor > 20) {
      latOffset = 0.00019898425; 
      lngOffset = 0.000000735105125 * sizeFactor;
      console.log('SizeFactor > 20, latOffset:', latOffset, 'lngOffset:', lngOffset);
    }
  
    return {
      north: lat + latOffset,  // Height (north-south)
      south: lat - latOffset,
      east: lng + lngOffset,   // Width (east-west)
      west: lng - lngOffset
    };
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey,
    libraries: ['places'],
  });

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoomLevel}
      mapTypeId="satellite"
      onLoad={onLoad}
      onZoomChanged={onZoomChanged}
    >
      {mainOverlay && (
        <GroundOverlay
          url={mainOverlay.imageUrl}
          bounds={mainOverlay.bounds}
          opacity={currentOverlayOpacity}
        />
      )}
      {otherOverlays.map((overlay, index) => (
        <GroundOverlay
          key={index}
          url={overlay.imageUrl}
          bounds={overlay.bounds}
          opacity={isOverlayVisible(index) ? currentOverlayOpacity : 0}
        />
      ))}

      {rectangles.map((rect, index) => (
        <Rectangle
          key={rect.id || index}
          bounds={rect.bounds}
          options={{
            strokeColor: "#000000",
            strokeOpacity: 0,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0,
            clickable: true,
            zIndex : 1,
          }}
          onClick={() => {
            const rectangleId = rect.id;
            setSelectedRectangle({ ...rect, id: rectangleId });
            onRectangleClick(rectangleId);
          }}
        />
      ))}

      {faults.map((fault, index) => {
        const lat = fault.gmap_lat || fault.lat;  // Use gmap_lat if available
        const lng = fault.gmap_lng || fault.lng;  // Use gmap_lng if available
        const bounds = getRectangleBounds(lat, lng, fault.effected_panels); // Generate bounds

        return (
          <Rectangle
            key={index}
            bounds={bounds}
            options={{
              strokeColor: "#000000",
              strokeOpacity: hoveredFaultIndex === index ? 0.8 : 0,
              strokeWeight: 2,
              fillColor: "#FF0000",
              fillOpacity: 0,
              clickable: true,
              zIndex: 9999999 - fault.effected_panels
            }}
            onClick={() => {
              setSelectedFault(fault);
            }}
          />
        );
      })}

      {selectedRectangle && (
        <InfoWindow
          position={{
            lat: (selectedRectangle.bounds.north + selectedRectangle.bounds.south) / 2,
            lng: selectedRectangle.bounds.west
          }}
          onCloseClick={() => setSelectedRectangle(null)}
        >
          <div className='info-window-content'>
            <h2>Inverter Block Details</h2>
            <p>{selectedRectangle.details}</p>
            {renderInfoWindowContent(selectedRectangle.id)}
          </div>
        </InfoWindow>
      )}

      {selectedFault && (
        <InfoWindow
          position={{
            lat: selectedFault.gmap_lat || selectedFault.lat,
            lng: selectedFault.gmap_lng || selectedFault.lng,
          }}
          onCloseClick={() => setSelectedFault(null)}
        >
          {renderFaultInfoWindowContent(selectedFault, selectedFault.category, selectedFault.level, siteName)}
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

export default MainView;