import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore'; 
import { auth, db } from '../utils/firebase';
import { Button, TextField, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // For navigation
import './accountInfo.css';

const AccountInfo = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);  
  const [formData, setFormData] = useState({});  
  const navigate = useNavigate();  // For navigating back to the welcome page

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;

      if (user) {
        const q = query(
          collection(db, 'userAccounts'), 
          where('email', '==', user.email) 
        );
        try {
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              setUserData(doc.data()); 
              setFormData(doc.data());  
            });
          } else {
            setError('No user data found');
          }
        } catch (err) {
          setError('Failed to fetch user data');
          console.error(err);
        }
      } else {
        setError('No user is logged in');
      }
      setLoading(false);
    };

    fetchUserData();
  }, []);

  const handleEditToggle = () => {
    setEditMode(!editMode);  
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    const user = auth.currentUser;
    if (user) {
      const q = query(
        collection(db, 'userAccounts'), 
        where('email', '==', user.email) 
      );
      try {
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          querySnapshot.forEach(async (docSnap) => {
            const docRef = doc(db, 'userAccounts', docSnap.id);
            await updateDoc(docRef, formData);  
            setUserData(formData);  // Immediately update the displayed data
          });
        }
      } catch (err) {
        console.error("Error updating document: ", err);
      }
    }
    setEditMode(false);  
  };

  if (loading) {
    return <p>Loading user data...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="account-info-container">
      <Typography variant="h4" gutterBottom>
        Account Information
      </Typography>
      {userData && (
        <Box>
          {editMode ? (
            <>
              <TextField
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled 
              />
              <TextField
                label="Phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Role"
                name="role"
                value={formData.role}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <Button variant="contained" onClick={handleSave} style={{ marginTop: '20px' }}>
                Save Changes
              </Button>
              <Button onClick={handleEditToggle} style={{ marginLeft: '10px' }}>
                Cancel
              </Button>
            </>
          ) : (
            <>
              <Typography variant="body1">
                <strong>Name:</strong> {userData.name}
              </Typography>
              <Typography variant="body1">
                <strong>Email:</strong> {userData.email}
              </Typography>
              <Typography variant="body1">
                <strong>Phone:</strong> {userData.phone || 'N/A'}
              </Typography>
              <Typography variant="body1">
                <strong>Role:</strong> {userData.role || 'N/A'}
              </Typography>
              <Button variant="outlined" onClick={handleEditToggle} style={{ marginTop: '20px' }}>
                Edit Info
              </Button>
            </>
          )}
        </Box>
      )}
      {/* Back to Welcome Page button */}
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/')}
        style={{
          position: 'fixed',
          bottom: '20px',
          left: '20px',
          padding: '10px 20px',
        }}
      >
        Back to Welcome Page
      </Button>
    </div>
  );
};

export default AccountInfo;
