import React, { useState } from 'react';
import './DashSideBar.css';

// Function to format the date
const formatDate = (dateString) => {
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  if (typeof dateString === 'string' && dateString.length === 8) {
    const month = parseInt(dateString.slice(0, 2), 10) - 1;
    const day = parseInt(dateString.slice(2, 4), 10);
    const year = parseInt(dateString.slice(4), 10);

    return `${monthNames[month]} ${day}, ${year}`;
  } else {
    return dateString; 
  }
};

const DashSideBar = ({ availableDates, selectedDate, setSelectedDate }) => {
  // State to handle sidebar visibility
  const [isMinimized, setIsMinimized] = useState(false);

  // Toggle sidebar visibility
  const toggleSidebar = () => {
    setIsMinimized(prevState => !prevState);
  };

  return (
    <div className={`dash-sidebar ${isMinimized ? 'minimized' : ''}`}>
      {/* Menu Icon to Toggle Sidebar */}
      <button className="menu-icon" onClick={toggleSidebar}>
        &#9776; {/* Unicode character for the hamburger icon */}
      </button>

      {/* Render the sidebar content only if it's not minimized */}
      {!isMinimized && (
        <>
          <h3>Select Date</h3>
          <ul>
            {availableDates.map((folder) => (
              <li 
                key={folder.date} 
                className={folder.date === selectedDate ? 'active' : ''}
                onClick={() => setSelectedDate(folder.date)}
              >
                {formatDate(folder.date)}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default DashSideBar;
